import axios from 'axios';
import { route } from 'preact-router';
import preactLocalStorage from 'preact-localstorage';
import store from './store';

let instance = axios.create({
    baseURL: 'https://api.dev.gvw.grosseteub.fr'
    //baseURL: 'http://172.27.0.6:3000'
});

// Add a request interceptor
/*axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    console.log(config);
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
*/

// Add a response interceptor
instance.interceptors.response.use((response) => {
    // Do something with response data
    return response;
}, (error) => {
    // Do something with response error
    //console.log(error.response.data.errors);
    if (error.response.data.errors[0].msg === "TOKEN_ERROR" && error.response.data.errors[1].msg === "Token Error")
    {
	preactLocalStorage.remove('token');
	store.delete('token');
        route('/signin', true);
    }
    return Promise.reject(error);
});

export default instance;
