// Must be the first import
//import "preact/debug";
//import "preact/devtools";

import { h, Component } from 'preact';
import { Router, route } from 'preact-router';

import preactLocalStorage from 'preact-localstorage';

import Header from './header';

// Code-splitting is automated for routes
//import Home from '../routes/home';
import SignUp from '../routes/signup';
import LogIn from '../routes/login';
import Profile from '../routes/profile';
import Room from '../routes/room';
import ListRooms from '../routes/rooms';
import CreateRoom from '../routes/createRoom';

import axios from '../request';

import store from '../store'

export default class App extends Component {
	
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */

    state = {
	token: ''
    };

    constructor(props){
	super(props);
	this.state.token = preactLocalStorage.get('token', undefined, true);
	console.log(this.state.token);
	axios.defaults.headers.common['token'] = this.state.token;
	axios.get('/user/get')
            .then((response) => {
                //console.log(response.data);
		store.set('token', this.state.token);
            })
            .catch((error) => {
		//console.log(error.response.data);
            });
    }
    
    handleRoute = e => {
	console.log(e.url);
	//this.state.token = preactLocalStorage.get('token', null, true);
	/*if (this.state.token !== null)
	    this.currentUrl = e.url;
	else{
	    this.currentUrl = "/signin?redirect=" + e.url;
	    e.url = "/signin";	    */
	this.state.token = preactLocalStorage.get('token', null, true);
	if (this.state.token === null && e.url != "/signin" && e.url != "/signup")
	    route('/signin', true);
    };

	render() {
		return (
			<div id="app">
			<Header token={this.state.token} />
			<Router onChange={this.handleRoute}>
			                <ListRooms path="/" />
			                <ListRooms path="/rooms" />
			                <SignUp path="/signup" />
			                <LogIn path="/signin" />
					<Profile path="/profile/" user="me" />
			                <Profile path="/profile/:user" />
			                <Room path="/room/:id" />
			                <CreateRoom path="/room/create" />
				</Router>
			</div>
		);
	}
}
