import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Link } from 'preact-router/match';
import style from './style.css';

import preactLocalStorage from 'preact-localstorage';
import { route } from 'preact-router';

import store from '../../store';

import SignOut from 'preact-icons/fa/sign-out';
import Plus from 'preact-icons/fa/plus';
import User from 'preact-icons/fa/user';

import List from 'preact-icons/fa/list-alt';

import Hamburger from 'preact-icons/fa/bars';

const Header = () => {
    let identity = store.paths('token');

    const token = identity.link(useState());

    const [connected, setConnected] = useState(Boolean(token[0]));

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    
    identity.onUpdate((data) => {
	//console.log(`data changed ${  data}`);
	if (data !== null && data !== undefined)
	    setConnected(true);
	else
	    setConnected(false);
	//console.log(token);
    });
        
    const Logout = () => {
	setConnected(false);
	store.delete('token');
	preactLocalStorage.remove('token');
	route('/signin');
    }

    const onClickBar = () => {
	setIsMenuOpen(!isMenuOpen);
	console.log(isMenuOpen);	
    }

    const Menu = isMenuOpen ? style.responsive : '';
    
	return (
	        <header class={`${style.header  } ${  Menu}`}>
                <h1>GVW App</h1>
                <nav>
		<Link activeClassName={style.active} class={style.icon} onClick={onClickBar}><Hamburger /></Link>
		{!connected
		 ?<>
                 <Link activeClassName={style.active} href="/signup">Sign Up</Link>
                 <Link activeClassName={style.active} href="/signin">Sign In</Link>
		 </>
		 :<>
                 <Link activeClassName={style.active} href="/rooms"><List /> <label class={style.legend}>Rooms</label></Link>  
                 <Link activeClassName={style.active} href="/profile"><User /> <label class={style.legend}>Profile</label></Link>
		 <Link activeClassName={style.active} href="/room/create" ><Plus /> <label class={style.legend}>Add a room</label></Link>
                 <Link activeClassName={style.active} onClick={Logout}><SignOut /> <label class={style.legend}>Sign Out</label></Link>
		 </>
	         }
                </nav>
                </header>
	);
}
export default Header;
